import React from "react";
import Font from "../components/Font/Font";

export default class SlaveStyles extends React.PureComponent {
  render() {
    const { primaryColor, customCSS = "", fonts, design } = this.props;

    const backgroundImage = design?.slide?.backgroundImage;

    // console.log("primaryColor", primaryColor);
    // console.log("customCSS", customCSS);
    // console.log("design", design);

    return (
      <>
        {fonts && <Font type="body" font={fonts.body} />}

        <style
          dangerouslySetInnerHTML={{
            __html: `
/* Color styles */
.slave-container--default {
  background-color: ${primaryColor};
}

.slave-container--modern .topBorder {
  background-color: ${primaryColor};
}
.slave-container--modern .question-container__question--number {
  border-color: ${primaryColor};
  color: ${primaryColor};
}

.slave-container--modern.slave-container--multiple_choice .question-chevron,
.slave-container--modern.slave-container--multiple_choice .answer-text.correct-answer {
  color: ${primaryColor};
}


.slave-container--title,
.slave-container--form {
  background-color: ${primaryColor};
}

.slave-container--stand .fa.fa-chevron-right {
  color: ${primaryColor};
}

/* Custom CSS */
${customCSS}

/* Background image */
${
  backgroundImage &&
  `
.slave-container {
  background-image: url(${backgroundImage.uri});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
`
}
`,
          }}
        />
      </>
    );
  }
}
